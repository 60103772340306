
.jetton-list__icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    &--rastopyrka {
        visibility: hidden;
        height: 0;
    }
}

.jetton-list-link {
    display: flex;
    align-items: center;
}

.tx-token-cell {
    display: flex;
    align-items: center;
    max-width: 100%;
    box-sizing: border-box;
    width: 100%;
}

.ui-table-mobile {
    table-layout: fixed;
}

.ui-table-mobile td {
    padding: 0!important;
    border-top: 0!important;
}

.ui-table-mobile .tx-mobile-content {
    border-bottom: 1px solid var(--card-row-separator);
    padding: 1em 14px 3px 5px;
}

.ui-table-mobile tr:last-child .tx-mobile-content {
    border-bottom: none;
}
