
.ui-inline-jetton {
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    &__value {
        margin-right: 4px;
    }
    &__link {
        display: flex;
        align-items: center;
    }
    &__avatar {
        margin: -4px -1px -4px 6px;
        width: 20px;
        height: 20px;
    }
    &--green {
        color: var(--badge-green-color);
    }
    &--red {
        color: var(--badge-red-color);
    }
    &--green, &--red {
        // if the amount is colored, the link should also be colored:
        .ui-inline-jetton__link {
            color: currentColor;
        }
    }
}
