
.top-balances-table-container {
    max-width: 820px;
    margin: 0 auto;
    .ui-table {
        td, th {
            &:first-child {
                padding: 0 0 0 16px;
            }
        }
    }
}

.top-balances-header {
    text-align: center;
    padding: 24px 0 26px;

    &__icon {
        font-size: 48px;
        margin-bottom: 12px;
    }

    &__title {
        font-weight: 500;
        font-size: 30px;
        margin: 0;
    }

    &__lead {
        margin-top: 12px;
        color: var(--body-muted-text-color);
        font-size: 16px;
        font-weight: normal;
    }
}

@media all and (max-width: 480px) {
    .top-balances-header {
        padding: 18px 6px 16px;
        &__title {
            font-size: 24px;
        }
        &__lead {
            font-size: 14px;
            line-height: 1.5;
        }
    }

    .top-balances-table-container {
        .ui-table {
            td, th {
                &:nth-child(2n) {
                    max-width: 35vw;
                    padding-left: 8px;
                }
            }
        }
    }
}

.whales__card {
    overflow: hidden;
}
