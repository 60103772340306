
.tx-code-json {
    position: relative;

    &__hide {
        height: 95px;
        overflow: hidden;
    }

    &__hide::after {
        content: "";
        position: absolute;
        bottom: 1px;
        left: 1px;
        right: 1px;
        height: 120px;
        background: linear-gradient(to top, var(--hidden-json-expander) 20%, transparent);
        pointer-events: none;
        border-radius: 0 0 8px 8px;
    }
}

.tx-code {
    padding: 10px;
    display: block;
    white-space: pre-wrap;
    word-break: break-all;
}

.tx-code-json-copy {
    background: var(--tx-messages-message-background);
}

.tx-code-expander {
    position: absolute;
    bottom: 20px;
    left: 50%;
    font-weight: 500;
    cursor: pointer;
    z-index: 900;
    transform: translate(-50%, 0%);
}
