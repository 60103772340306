
.page-apps-navigation {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 72px;
}

.page-apps-navigation-item {
    padding-left: 10px;
    display: flex;
    align-items: center;
    height: 48px;
    color: var(--apps-navigation-inactive-color);
    cursor: pointer;
    transition: 0.1s ease-in-out;
    font-weight: 500;

    &:hover {
        color: var(--apps-navigation-hover-color);
        text-decoration: none;
    }

    &__content {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
    }

    &__name {
        margin-left: 16px;
        line-height: 24px;
    }

    &__icon {
        width: 24px;
        height: 24px;
    }

    &--active {
        color: var(--apps-navigation-icon-active);
        &:hover {
            color: var(--apps-navigation-icon-active);
        }
    }
}
