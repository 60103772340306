
.event-name{
    padding: 4px 10px 4px 6px;
    font-size: 13px;
    background: var(--nft-preview-background);
    border-radius: 8px;
    color: inherit;
    display: flex;
    align-items: center;

    &__icon {
        width: 17px;
        height: 17px;
        display: inline-block;
        margin-right: 6px;

        &--chain {
            transform: scale(.8);
        }
    }
}
