
.tx-row-tx-event-action-badge {
    display: flex;
    align-items: center;
}

@media (max-width: 599px) {
    .ui-inline-nft-mobile-swap {
        transform: none!important;
        max-height: 30px;
    }
}
