
.block-info-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 82px;
    grid-gap: 14px;
    margin-bottom: 18px;
    .information-block {
        margin-top: 0 !important;
    }
}

.block-recent-tx-section {
    height: 356px;
    overflow: auto;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
        display: none;
    }
}

.block-chains-section {
    display: grid;
    width: 100%;
    align-items: stretch;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .card + .card {
        margin: 0;
    }
}

@media screen and (max-width: 900px) {
    .block-info-section {
        grid-template-columns: repeat(2, 1fr);
    }

    .block-chains-section {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 599px) {
    .block-info-section {
        grid-template-columns: 1fr;
        grid-gap: 0;
        margin-bottom: 14px;

        .information-block {
            border-radius: 0;
            border-bottom-width: 0;
            &:first-child {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
            }
            &:last-child {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                border-bottom-width: 1px;
            }
        }

        .chart-box {
            &__value {
                font-size: 24px;
            }
        }
    }
}
