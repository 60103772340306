

@media screen and (max-width: 600px) {
    .widget-apps {
        padding: 5px 0 10px 0;
    }

    .widget-apps-item {
        display: flex;
        align-items: center;
        padding: 10px 10px;
        margin: 0 0 8px 0;
        border-radius: 10px;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
    }

    .widget-apps a:hover {
        background: var(--body-light-muted-color);
    }

    .widget-apps-item:last-child {
        margin-bottom: 0;
    }

    .widget-apps-item:hover {
        text-decoration: none;
    }

    .widget-apps-img {
        width: 60px;
        flex: 0 0 60px;
        border-radius: 14px;
        margin-right: 18px;
        overflow: hidden;
        -webkit-transition: all .2s;
        -o-transition: all .2s;
        transition: all .2s;
    }

    .widget-apps-name {
        color: var(--body-text-color);
        margin-bottom: 6px;
        font-size: 16px;
        font-weight: 500;
    }

    .widget-apps-description {
        color: var(--body-muted-text-color);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
    }

    .widget-apps-item img {
        width: 100%;
        vertical-align: top;
    }
}
