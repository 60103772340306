
.tx-block-mobile-content {
    &__row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        box-sizing: border-box;
        white-space: nowrap;
    }

    &__left {
        flex-shrink: 1;
        padding-right: 8px;
    }

    &__right {
        flex-shrink: 0;
        text-align: right;
        padding-left: 8px;
    }

    &__left, &__right {
        display: inline-flex;
        min-width: 0;
    }
}
