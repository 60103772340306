
.ads-page-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin: 30px 0 10px 0;
    box-sizing: border-box;

    &__column {
        width: 100%;
        max-height: 667px;
    }
}

.ads-page-header {
    font-weight: 800;
    font-size: 28px;
    margin-top: 60px;
    margin-bottom: 0;
    text-align: center;
    font-family: Nunito, Rubik, Roboto, Ubuntu, Arial, sans-serif;
}

.ads-page-logos {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 16px 10px;
    justify-content: space-around;
    max-width: 800px;
    margin: 20px auto;

    svg {
        cursor: pointer;
        height: 37px;
        transition: .3s ease;
        color: var(--body-muted-text-color);
        &:hover {
            color: var(--body-text-color);
        }
    }
}

.contact-us {
    padding: 50px 0;
    text-align: center;
    margin-top: 10px;
    width: 100%;

    &__header {
        font-weight: 800;
        font-size: 28px;
        margin: 0;
        font-family: Nunito, Rubik, Roboto, Ubuntu, Arial, sans-serif;
    }

    &__text {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    &__button {
        display: inline-block;
        width: auto;
        text-align: center;
        border: none;
        border-radius: 8px;
        color: #FFF;
        background-color: var(--big-blue-button-background);
        padding: 10px 18px;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        cursor: pointer;
        margin: 0 auto;

        &:hover {
            text-decoration: none;
            background-color: var(--big-blue-button-hover-background);
        }
    }
}

@media screen and (max-width: 1230px){
    .ads-page-container {
        grid-template-columns: 1fr;
        max-width: 600px;
        margin: 0 auto;
    }
}
