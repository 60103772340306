
.tx-mobile-block-row {
    margin-bottom: 4px;
}

.tx-mobile-block-row:last-child {
    margin-bottom: 0;
}

.tx-mobile-top-margin {
    margin-top: 11px;
}
