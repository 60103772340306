
.jetton-chart-container {
    width: 100%;
    aspect-ratio: 16 / 7;
    display: flex;
}

.interval-selector__item {
    text-align: center;
}

@media screen and (max-width: 991px) {
    .interval-selector-jetton {
        width: 100%;
        justify-content: space-around;

        .interval-selector__item {
            width: 100%;
            padding: 4px 0;
            text-align: center;
        }
    }
    .jetton-chart-container {
        padding: 0 0 0 10px;
        width: calc(100% - 10px);
        aspect-ratio: 16 / 12;
    }
}
