
.card-featured-jetton {
    margin-top: 30px;
    padding: 12px 16px 0 16px;
    display: flex;
    justify-content: space-between;
    user-select: none;

    font-size: 20px;

    &__image {
        width: 32px;
        height: 32px;
    }
}

.chart-featured-jettons {
    margin-top: -8px;
    pointer-events: none;

    &--overlay {
        cursor: pointer;
    }
}

.card-featured-info {
    display: flex;
    font-size: 14px;
    margin-left: 10px;
    flex-direction: column;
}
