
.index-ad-block {
    //border: none!important;
    border: 1px solid var(--header-border-bottom-color)!important;
    background: linear-gradient(to right, #2E2E2E, #1D1D1D);
    font-family: Nunito, Rubik, Roboto, Ubuntu, Arial, sans-serif;
    padding: 10px 20px 16px 20px;
    color: white;
    position: relative;
    min-width: 0;
    box-sizing: border-box;

    &__sponsored-tag {
        font-size: 12px;
        font-weight: 800;
        color: white;
        background-color: hsla(0, 0%, 100%, .19);
        display: inline-block;
        border-radius: 5px;
        padding: 2px 6px;
        margin-bottom: 7px;
        text-transform: uppercase;

        &:hover {
            background-color: hsla(0, 0%, 100%, .25);
            text-decoration: none;
        }
    }

    &__header {
        font-weight: 800;
        font-size: 22px;
        margin-bottom: 4px;
    }

    &__description {
        max-width: 180px;
        b {
            font-weight: 800;
        }
    }

    &__close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        color: white;
        fill: white;

        width: 20px;
        height: 20px;
        background-color: var(--body-light-muted-color);
        border-radius: 15px;
        z-index: 100;
        backdrop-filter: blur(5px);
    }
}

.index-ad-grid {
    display: grid;
    grid-template-columns: 220px auto;
}

@media screen and (max-width: 1170px) {
    .index-ad-grid {
        grid-template-columns: 400px auto;
        margin-top: 10px;
    }
}

.index-ad-button {
    display: block;
    text-align: center;
    width: 100%;
    background: #fff;
    padding: 10px 0;
    border-radius: 8px;
    font-size: 16px;
    margin-top: 15px;
    text-transform: uppercase;
    color: #1D1D1D;
    font-weight: 500;
    font-family: Rubik, Roboto, Ubuntu, Arial, sans-serif;
    cursor: pointer;
    position: relative;
    z-index: 10;

    &:hover {
        background: #f3f3f3;
        text-decoration: none;
    }
}

.index-ad-img {
    position: absolute;
    width: 140px;
    height: auto;
    top: 2px;
    right: 5px;
    z-index: 9;

    &--grid {
        position: absolute;
        width: auto;
        height: 200px;
        top: 0;
        right: 0;
        z-index: 8;
    }
}

@media screen and (max-width: 1170px) {
    .major-img {
        width: 180px;
    }
}
