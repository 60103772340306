
.index-ad-block-mobile-container {
    padding: 0 16px;
    box-sizing: border-box;
    width: 100%;
    margin-top: 16px;
}

.index-ad-block-mobile {
    display: block;
    border: 1px solid var(--header-border-bottom-color);
    background: linear-gradient(to right, #2E2E2E, #1D1D1D);
    font-family: Nunito, Rubik, Roboto, Ubuntu, Arial, sans-serif;
    color: white;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border-radius: 12px;
    box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
    font-size: 14px;
    overflow: hidden;
    padding: 10px 16px;
    cursor: pointer;

    &__header {
        font-size: 16px;
        font-weight: 800;
        margin-bottom: 4px;
    }

    &__description {
        max-width: 240px;
        z-index: 100;
        font-family: Rubik, Roboto, Ubuntu, Arial, sans-serif;

        b {
            font-weight: 500;
        }
    }

    &__ads-tag {
        display: inline-block;
        transform: translate(3px, 2px);
        margin-left: 4px;
        font-size: 10px;
        padding: 1px 7px;
        background: var(--card-pill-background);
        border-radius: 10px;
        font-weight: 800;
        text-transform: uppercase;
        background-color: rgba(255, 255, 255, 0.19);
        color: white;
        z-index: 99;
        position: absolute;
        &:hover {
            text-decoration: none;
        }
    }

    &__close-btn {
        position: absolute;
        top: 6px;
        right: 22px;
        cursor: pointer;
        color: white;
        fill: white;

        width: 20px;
        height: 20px;
        background-color: var(--body-light-muted-color);
        border-radius: 15px;
        z-index: 100;
        backdrop-filter: blur(5px);
    }

    &:hover {
        text-decoration: none;
    }
}

.index-ad-img-mobile {
    position: absolute;
    width: 84px;
    top: 5px;
    right: 8px;
    z-index: 9;

    &--grid {
        position: absolute;
        width: auto;
        height: 200px;
        top: 0;
        right: 0;
        z-index: 8;
    }
}

.index-ad-mobile-arrow-right {
    width: auto;
    height: 18px;
    margin: 0 0 -4px -7px;
}
