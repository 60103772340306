
.tx-table {
    &__badge {
        &--burn {
            color: var(--badge-red-color)!important;
            background-color: var(--badge-red-background-color)!important;
        }
        &--sale {
            color: var(--badge-orange-color)!important;
            background-color: var(--badge-orange-background-color)!important;
        }
        &--sent {
            color: var(--badge-blue-color)!important;
            background-color: var(--badge-blue-background-color)!important;
        }
        &--bid {
            color: var(--badge-orange-color)!important;
            background-color: var(--badge-orange-background-color)!important;
        }
        &--mint {
            color: var(--badge-green-color)!important;
            background-color: var(--badge-green-background-color)!important;
        }
        &--cancel {
            color: var(--badge-red-color)!important;
            background-color: var(--badge-red-background-color)!important;
        }
        &--sold {
            color: var(--badge-green-color)!important;
            background-color: var(--badge-green-background-color)!important;
        }
        &--value {
            text-transform: uppercase;
        }
        &--items {
            display: flex;
            align-items: center;
            padding: 0 13px 0 8px;
            gap: 5px;
        }
        &--icon {
            svg {
                display: block;
                width: 18px;
            }
        }
    }
}
