
.indexpage-mobile-numbers {
    width: 100%;
    height: auto;
    display: flex;
    box-sizing: border-box;
    padding: 0 16px;

    &__ton-logo {
        width: 20px;
        height: 20px;
        margin-right: 2px;
        fill: var(--badge-blue-color);
    }

    &__block {
        &--left {
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        &--right {
            color: var(--body-muted-text-color);
            text-align: right;
            flex-grow: 1;
        }
    }
}
