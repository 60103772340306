
.search-result-fancy {
    &__category {
        display: inline-flex;
        align-items: center;
        font-weight: normal;
        opacity: .45;
        font-size: 12px;
        &::before {
            opacity: .65;
            content: '·';
            padding: 0 6px;
        }
    }
    &__address {
        margin: 0;
        font-size: 13px;
        opacity: .7;
    }
}
