
.card-market-jetton-percent {
    font-size: 14px;
}

.card-jetton-indicator {
  &--red {
    color: #f74b4c;
  }

  &--green {
    color: #3fb950;
  }

  &--gray {
    color: #888888;
  }
}
