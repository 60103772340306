
.app-header-dropdown {
    position: absolute;
    top: 50px;
    left: -12px;
    z-index: 1000;

    &__container {
        background: var(--card-background);
        border: 1px solid var(--card-border-color);
        box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
        border-radius: 12px;
    }

    &__items {
        display: flex;
        flex-direction: column;
        gap: 2px;
        padding: 8px;
    }

    &__item:first-child &__anchor {
        padding: 10px 13px 10px 13px;
    }

    &__item &__anchor {
        display: flex;
        align-items: center;
        padding: 10px 13px;
        border-radius: 8px;
        white-space: nowrap;
    }

    &__anchor-icon {
        width: 24px;
        height: 24px;
        margin: -5px 9px -5px -5px;
    }

    &__item &__anchor:hover, &__item-active &__anchor {
        background: var(--body-light-muted-color);
    }

    &__item:last-child &__anchor {
        padding: 10px 13px 10px 13px;
    }
}
