
.search-result-app {
    &__tag {
        font-size: 10px;
        padding: 1px 7px;
        background: var(--card-pill-background);
        border-radius: 10px;
        margin: -2px 8px;
        font-weight: 400;
        color: var(--app-search-badge-text-color);
    }
    &__description {
        margin: 0;
        font-size: 13px;
        opacity: .7;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
