
.ui-inline-nft {
    display: flex;
    align-items: center;
    background: var(--nft-preview-background);
    border-radius: 8px;
    margin: -4px 0;
    color: inherit;
    transition: .2s;
    &__left {
        width: 26px;
        height: 26px;
        border-radius: 8px 0 0 8px;
        overflow: hidden;
    }
    &__title {
        padding: 5px 10px;
        font-size: 13px;
        p {
            margin: 0;
            padding: 0;
        }
    }
    &__collection {
        margin: 0;
        font-size: 12px;
    }
    &:hover {
        background: var(--nft-preview-hover-background);
        color: var(--nft-preview-hover-color);
        text-decoration: none;
    }
}

@media screen and (max-width: 600px) {
    .ui-inline-nft{
        flex-direction: row-reverse;
        border-radius: 8px;
        margin-top: -2px;
        margin-bottom: -30px;
        background-color: transparent!important;

        &__left {
            width: 40px;
            height: 40px;
            border-radius: 8px;
        }
        &__title {
            padding: 0 8px 0 0;
            font-size: 13px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 44px;
            text-align: right;
            max-width: 130px;
            p {
                margin: 0;
                padding: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            //width: calc(100% - 70px);
        }
        &:hover {
            background: transparent;
            color: var(--body-text-color);
        }
    }
}

.ui-inline-nft-image {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--card-border-color);
    color: var(--body-text-color);
    &__img {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1000;
        transform: scale(1.1);
    }
    &__svg {
        width: 14px;
        height: 14px;
        opacity: 0.8;
        z-index: 100;
    }
}
