
.last-blocks-mini-table {
    overflow: hidden;

    td, th {
        text-align: center;
    }
}

.card-chain-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;

    &__title {
        text-transform: uppercase;
        font-weight: 500;
        color: var(--card-header-color);
    }

    &__link {
        text-transform: uppercase;
        font-weight: 500;
    }

    &__link a:hover {
        text-decoration: none;
        color: var(--widget-view-all-btn-hover);
    }
}

.card-chain-footer {
    display: none;
    padding: 8px 10px 18px 10px;
}

.card-chain-show-more {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    border: none;
    border-radius: 8px;
    color: var(--body-text-color);
    border: 1px solid var(--card-border-color);
    background-color: transparent;
    padding: 10px 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;

    &__icon {
        width: 9px;
    }
}

@media screen and (max-width: 480px) {
    .card-chain-header {
        &__link {
            display: none;
        }
    }

    .card-chain-footer {
        display: block;
    }
}
