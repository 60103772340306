
.page-apps {
    margin-top: -1px;

    &__content {
        padding-left: 20px;
    }

    &__search-filter-wrapper {
        display: flex;
        gap: 15px;
    }

    &__search {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1240px) {
    .page-apps {
        &__content {
            padding: 0 8px;
            border-radius: 0;
        }
    }
}

@media screen and (max-width: 480px) {
    .page-apps {
        &__content {
            padding: 0;
            margin: 0 -7px;
        }
    }
}
