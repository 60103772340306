
.vesting-sender {
    display: inline-block;
    background: var(--blue-bright-background);
    color: var(--blue-bright);
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 10px;
    padding: 1px 8px;
    margin: -10px 0 -10px 6px;
    transform: translateY(-2px);
    height: 16px;
    font-size: 10px;
    line-height: 17px;
    user-select: none;
}
