
.price-token-table {
    font-weight: 500;
}
.jetton-list-link a {
    display: inline-flex;
    align-items: center;
}
.jetton-list-ticker {
    margin-left: 6px;
}
