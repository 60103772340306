
.search-result-domain {
    font-size: 15px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    &__tld {
        font-weight: normal;
        opacity: .6;
    }
}
