
.filter {
    padding: 0 10px;
    position: relative;
    z-index: 1020;
}

// .filter-hidden {
//     display: none;
// }

.filter-button {
    cursor: pointer;
    position: relative;
}

.filter:before {
    content: "";
    height: 100%;
    width: 80px;
    position: absolute;
    left: -75px;
    top: 0;
    background: linear-gradient(to left, var(--card-background), transparent);
    pointer-events: none;
}

.filter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 90000;
}

.no-scroll-body {
    position: fixed;
    width: 100%;
    overflow: hidden;
}

@media screen and (max-width: 599px) {
    .filter-overlay {
        background: rgba(0,0,0,0.4);
    }
}
