
@media screen and (max-width: 600px) {
    .widget-wallet {
        padding: 20px 10px;
    }

    .widget-wallet-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 22px;
        font-size: 14px;
    }

    .widget-wallet-item:last-child {
        margin-bottom: 0;
    }

    .widget-wallet-data {
        display: flex;
        align-items: center;
    }

    .widget-wallet-balance {
        white-space: nowrap;
        font-weight: 500;
        margin-left: 24px;
    }

    .widget-wallet-icon {
        width: 18px;
        height: 18px;
        margin-left: 10px;
        fill: var(--body-muted-text-color);
        transform: translateY(-1px);
    }
}
