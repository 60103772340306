
.mobile-switcher-tabs-container {
    width: calc(100% + 20px);
    margin-left: -10px;
    overflow-x: auto;
    display: flex;
    padding: 5px 10px 10px 0;
    position: relative;
    box-sizing: border-box;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.mobile-switcher-tab {
    background-color: var(--tx-table-thead-alt-background) !important;
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--body-muted-text-color);
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 35px;
    margin-top: 0 !important;
    padding: 0 14px;
    position: relative;
    border: 2px solid var(--card-border-color);
    margin-right: 10px;
    transition: .2s;
    white-space: nowrap;

    &:first-child {
        margin-left: 10px;
    }

    &--active {
        background-color: var(--card-background);
        color: var(--body-text-color);
        border-color: var(--big-blue-button-hover-background);;
    }

    &:hover {
        color: var(--body-text-color);
    }
}

.mobile-selected-tab-container {
    background-color: var(--card-background);
    border-radius: 15px 15px 0 0;
    padding: 10px;
    min-height: 200px;
    overflow: hidden;
    border-top: 1px solid var(--card-border-color);
}

.mobile-tab-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    border-radius: 8px;
    color: var(--body-text-color);
    border: 1px solid var(--card-border-color);
    background-color: transparent;
    padding: 10px 0;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        // background-color: var(--big-blue-button-hover-background);
        text-decoration: none;
    }

    &__icon {
        width: 9px;
    }

    &__container {
        padding: 8px 10px 18px 10px;
    }
}

.fade-mobile-switcher-enter-active,
.fade-mobile-switcher-leave-active {
    transition: opacity .35s ease;
}

.fade-mobile-switcher-enter,
.fade-mobile-switcher-leave-to {
    opacity: 0;
}
