
.verifier-chapter-container {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    display: inline-flex;
    padding: 0 12px 0 0;
    margin: 10px 0;
    position: relative;
    box-sizing: border-box;
    scrollbar-color: transparent transparent;
}

@media screen and (min-height: 600px) {
    .verifier-chapter-container {
        display: flex;
        margin: 16px 0 10px;
        overflow-x: auto;
        overflow-y: auto;
    }
}

.verifier-chapter-btn {
    padding: 8px 12px;
    text-transform: uppercase;
    color: var(--body-text-color);
    border: 1px solid var(--address-tag-gray-background);
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    background: var(--button-options-background);
    transition: .1s background;
    margin-right: 12px;
    white-space: nowrap;

    &__active {
        border-color: var(--filter-item-active);
        background: var(--chapter-active-background);
    }

    &:first-child {
        margin-left: 12px;
    }

    &:hover:not(.verifier-chapter-btn__active) {
        background: var(--button-options-background-hover);
    }
}

.verifier-sources {
    display: flex;
    flex-direction: row;
    &__files {
        margin: 0 0 12px 12px;
        width: 240px;
        flex-shrink: 0;
        &__elevator {
            position: sticky;
            top: 12px;
        }
    }
    &__code {
        flex-grow: 1;
        overflow: auto;
        margin: 0 12px 12px 12px;
    }
}

.source-viewer-code__pre {
    display: flex;
    flex-direction: row;
    overflow: auto;
}

.hljs-hack-lines {
    padding: 0 10px 0 0;
    text-align: right;
    color: var(--body-muted-text-color);
}

.hljs-hack-code {
    padding: 0 10px 0 4px;
}

@media all and (max-width: 480px) {
    .verifier-sources {
        flex-direction: column;
        &__files {
            width: unset;
            margin-right: 12px;
        }
        &__code {
            margin-top: 0;
        }
    }
}
