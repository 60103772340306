
.widget-jettons {
    padding: 12px 0 10px 0;
}

.widget-jettons-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    margin: 0 10px;
    border-radius: 10px;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;

    &:hover {
        text-decoration: none;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.widget-jettons a:hover {
    background: var(--body-light-muted-color);
}

.widget-jettons-content {
    align-items: start;
    flex-grow: 1;
    margin-left: 4px;
    .widget-jettons-name {
        font-size: 14px;
        color: var(--body-text-color);
        font-weight: 500;
    }
    .widget-jettons-symbol {
        font-size: 14px;
    }
}

.widget-jettons-data {
    align-items: end;
}

.widget-jettons-content,
.widget-jettons-data {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.widget-jettons-price {
    font-weight: 500;
    margin-left: 20px;
    font-size: 14px;
    color: var(--body-text-color);
}

.widget-jettons-img {
    width: 32px;
    height: 32px;
    border-radius: 50px;
    overflow: hidden;
    margin-right: 10px;
}

.widget-jettons-img img {
    width: 100%;
    vertical-align: top;
}

.top-jetton-tooltip__icon {
    fill: currentColor;
    width: 14px;
    height: 14px;
    margin: -4px 0 -4px 3px;
    transform: translateY(-2px);
    cursor: pointer;

    &:hover {
        color: var(--blue-bright);
        text-decoration: none;
    }
}

.custom-tooltip-wrapper {
    position: absolute;
    padding: 12px 14px;
    color: var(--body-text-color);
    border-radius: 12px;
    top: -30px;
    font-size: 13px;
    text-transform: none;
    background: var(--card-background);
    background-clip: border-box;
    border: 1px solid var(--card-border-color);
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
    max-width: 400px;
    z-index: 1001;
}

.dyor-logo-widget {
    padding-bottom: 10px;
    text-align: center;
    font-size: 14px;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            color: var(--blue-bright);
        }
    }

    &__icon {
        width: 15px;
        height: 15px;
        fill: currentColor;
        transform: translateY(3px);
        margin-left: 2px;
    }
}
