
.event-name-simple{
    padding: 4px 10px 4px 6px;
    font-size: 13px;
    border-radius: 8px;
    color: inherit;
    display: flex;
    align-items: center;
    margin-right: 12px;

    &:last-child {
        margin-right: 0;
    }

    &__icon {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 4px;

        &--chain {
            transform: scale(.8);
        }
    }

    &--failed {
        color: var(--badge-red-color);
    }
}
