
.tx-overview-action-type{
    display: flex;
    align-items: center;
    margin-right: 12px;

    &__icon {
        width: 17px;
        height: 17px;
        display: inline-block;
        margin-right: 6px;
    }
}
