

.footer {
    padding: 25px 10px 0 10px;
    width: 100%;
    margin-top: 20px;
    box-sizing: border-box;
}

.footer-container {
    flex-grow: 1;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-nav {
    display: flex;
    gap: 20px;
    height: 100%;
    a {
        color: var(--body-muted-text-color);
        font-size: 14px;
        text-decoration: none !important;
        user-select: none;
    }
    a:hover {
        color: var(--body-text-color);
    }
}

.footer-nav a {
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}

.footer-copyright {
    color: var(--body-muted-text-color);
    font-size: 14px;
}
