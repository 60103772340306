
.info__item {
    //scroll-snap-align: end;
}

.info__item:first-child {
    margin-left: 16px;
}

.info__item:last-child {
    margin-right: 16px;
}

.item__name {
    color: var(--app-info-name);
    font-weight: 400;
    user-select: none;
}

.item__value {
    margin-top: 8px;
    font-weight: 500;

    .value-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }

    & svg {
        color: var(--icon-color);
        width: 16px;
        height: 16px;

        &:hover {
            color: var(--icon-hover-color);
        }
    }
}
