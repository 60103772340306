
.search {
    width: 100%;
    position: relative;
}

.search-input {
    display: flex;
    align-items: stretch;
    width: 100%;
    border: none;
    color: var(--body-text-color);
    box-sizing: border-box;
    position: relative;
    z-index: 200;

    &--expanded {
        background: var(--indexpage-search-background-color) !important;
        border: 2px solid var(--card-border-color) !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    &:focus-within {
        border-color: #2575ed !important;
    }

    &--error:focus-within {
        border: 2px solid !important;
        border-color: var(--red-bright) !important;
        animation: 0.4s shake linear;
    }

    &__loopa {
        width: 20px;
        height: 20px;
        opacity: .35;
        z-index: 100;
        align-self: center;
        flex-shrink: 0;
        transition: .1s all ease;
        overflow: hidden;
        fill: currentColor;
        &--hidden {
            width: 0;
        }
    }

    &__input {
        font-size: 1em;
        appearance: none;
        border: none;
        display: block;
        padding: 0;
        width: 100%;
        background: transparent;
        text-overflow: ellipsis;
        color: inherit;
        &::placeholder {
            color: #777;
        }
        &::-webkit-input-placeholder {
            color: #777;
        }
    }
}

.search-input-controls {
    display: flex;
    align-items: center;
    min-height: 100%;
    z-index: 1000;
    box-sizing: border-box;
    padding: 0.25em 0.65em;
    gap: 0.5em;
    &__loader {
        width: 18px;
        height: 18px;
        z-index: 900;
    }
    &__go {
        border-radius: 9px;
        background: #2575ed;
        color: #FFF;
        height: 100%;
        min-width: 64px;
        font-size: 16px;
        cursor: pointer;
        margin-right: -0.4em; // make right offset from container border the same with top and bottom offset
    }
    &__close {
        width: 1.625em;
        height: 1.625em;
        fill: currentColor;
        opacity: .7;
        padding: 8px;
        margin: -8px;
        cursor: pointer;
        transition: .1s opacity ease;
        &:hover {
            opacity: 1;
        }
    }
}

@keyframes shake {
    0% {
        left:-5px;
    }
    16% {
        left:4px;
    }
    33% {
        left:-3px;
    }
    50% {
        left:2px;
    }
    66% {
        left:-2px;
    }
    83% {
        left:1px;
    }
    100% {
        left: 0px;
    }
}
