
.jetton-link {
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    cursor: pointer;
    height: 72px;
}

.jetton-cap {
    font-weight: 400;
}

.jetton-link:hover {
    background: var(--body-light-muted-color);
}

.jetton-name,
.jetton-ticker {
    font-weight: 500;
    word-break: normal;
    max-width: 130px;
    overflow-wrap: break-word;
    white-space: normal;
}

.jetton-title-items {
    display: flex;
    align-items: center;
    gap: 10px;
}

@media screen and (max-width: 599px) {
    .jetton-title-items {
        flex-direction: column;
        text-align: left;
        gap: 0;
        align-items: normal;
    }
    .jetton-name {
        padding-bottom: 3px;
    }
    .jetton-ticker {
        max-width: none!important;
    }
    .jetton-image {
        width: 40px;
    }
}

.jetton-title {
    padding: 0 12px 0 0!important;
}

.jetton-name {
    width: 200px;
}

.jetton-image {
    padding: 0 12px !important;
    width: 32px;
}

.jetton-price {
    text-align: right;
    white-space: nowrap;
}

.jetton-ticker {
    font-weight: 400;
    color: var(--body-muted-text-color);
}

.jetton-hour {
    font-weight: 500;
    text-align: center;
    padding-left: 30px;
}

.jetton-day {
    font-weight: 500;
    text-align: center;
}

.jetton-week {
    font-weight: 500;
    text-align: center;
}

.jetton-mc {
    font-weight: 500;
    text-align: center;
}

.jetton-volume {
    text-align: center;
}

.jetton-theme-chart {
    height: 45px;
}
