
.tabs-address-formats {
    padding: 0 11px 12px;
    display: inline-block;
    width: auto;
    max-width: 100%;
    overflow-x: auto;
    box-sizing: border-box;

    .config-content-viewer {
        padding-right: 25px;
        overflow-x: auto;
    }
}
