
@media all and (max-width: 900px) {
    .tx-decoded-body {
        display: flex;
        flex-direction: column;

        &__title {
            padding: 6px 10px 6px 0;
            font-weight: 500;
            white-space: nowrap;
        }

        &__json {
            padding: 6px 0!important;
        }
    }
}
