
.card-row {
    &__name-tags {
        flex-shrink: 0;
        padding-right: 2ex;
        white-space: nowrap;
        width: 100px;
        color: var(--body-muted-text-color);
        margin-top: 1px;
    }

    &__value-tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
    }

    &__value-tag-item {
        position: relative;
    }

    &__value-tag {
        font-weight: 500;
        user-select: none;
        padding: 2px 9px;
        border-radius: 5px;
        border: 1px solid;
        position: relative;
        font-size: 12px;
    }

    &__value-skeleton {
        border: 0;
        padding: 3px 9px;
    }

    &__value-tag-green {
        border-color: transparent;
        background: var(--address-tag-green-background);
        color: var(--address-tag-green-color);
    }

    &__value-tag-blue {
        border-color: var(--address-tag-blue-background);
        background: var(--address-tag-blue-background);
        color: var(--address-tag-blue-color);
    }

    &__value-tag-orange {
        border-color: var(--address-tag-orange-background);
        background: var(--address-tag-orange-background);
        color: var(--address-tag-orange-color);
    }

    &__value-tag-red {
        border-color: var(--address-tag-red-border);
        background: var(--address-tag-red-background);
        color: var(--address-tag-red-color);
    }

    &__value-tag-gray {
        border-color: transparent;
        background: var(--address-tag-gray-background);
        color: var(--address-tag-gray-color);
    }

    &__value-tag-gray-line {
        background: transparent;
        border-color: var(--address-tag-gray-border);
        color: var(--body-text-color);
        user-select: all;
    }
}

.card-wallet-type {
    cursor: pointer;
    position: relative;

    &__dropdown {
        user-select: none;
        position: absolute;
        top: 30px;
        left: 50%;
        background-color: var(--card-background);
        z-index: 1000;
        border: 1px solid var(--card-row-separator);
        border-radius: 5px;
        white-space: nowrap;
        transform: translate(-50%, 0%);
    }

    &__dropdown-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999;
    }

    &__dropdown-item {
        padding: 7px 10px;
        border-bottom: 1px solid var(--card-row-separator);
    }

    &__dropdown-item:last-child {
        border-bottom: 0;
    }
}

.top-user-jettons {
    display: flex;
    flex-direction: row;
    gap: 0;
    padding-left: 10px;
    align-items: center;
    margin: -2px 0 -2px 0;

    &__img {
        width: 20px;
        height: 20px;
        margin-left: -10px;
        margin-right: 0;
        border-radius: 50%;
        border: 2px solid var(--card-background);
        box-sizing: content-box;
        background-color: var(--card-background);
    }

    &__text {
        margin-left: 4px;
    }

    a {
        margin-left: 4px;
    }
}

// @media (max-width: 40px) {
//     .card-wallet-type {
//         &__dropdown {
//             left: 40%;
//         }
//     }
// }

.card-domain-container {
    display: inline-flex;
}

.card-domain-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    padding-left: 4px;
    transform: translateY(1px);
}
