
.widget-channel-container {
    max-height: 600px;
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@media screen and (max-width: 480px) {
    .widget {
        width: 100%;
    }
}
