
.mobile-swap {
    text-transform: uppercase;

    &--green {
        color: var(--badge-green-color);
    }
    &--red {
        color: var(--badge-red-color);
    }
}
