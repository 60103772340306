
.filter-button {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    border-radius: 8px;
    border: 2px solid var(--card-row-separator);
    padding: 0 17px;
    line-height: 36px;
    user-select: none;
    z-index: 90001;
    svg {
        width: 100%;
        height: 100%;
    }
    &__icon {
        display: flex;
        align-items: center;
        width: 18px;
        color: var(--body-muted-text-color);
        fill: var(--blue-bright);
        height: 36px;
        z-index: 1;
    }
    &__title {
        text-transform: uppercase;
        font-weight: 500;
        color: var(--card-header-tab-color);
    }
}

.filter-button.active {
    border-color: var(--blue-bright);
}

.active {
    .filter-button {
        &__icon {
            color: var(--body-text-color);
        }
        &__title {
            color: var(--body-text-color);
        }
    }
}

@media screen and (max-width: 480px) {
    .filter-button, .filter-button.active {
        border-color: transparent;
        padding: 0 5px;
    }
}

