
@media screen and (max-width: 600px) {
    .widget-apps-img {
        &--skeleton {
            width: 60px;
            height: 60px;
            border-radius: 14px;
            margin-right: 18px;
            overflow: hidden;
            content: "";
            background-color: var(--body-light-muted-color);
        }
    }
}
