
.jetton-history-pair {
    text-transform: uppercase;
    &__left {
        color: var(--badge-red-color);

        a {
            color: var(--badge-red-color);
        }
    }
    &__right {
        color: var(--badge-green-color);

        a {
            color: var(--badge-green-color);
        }
    }
}
