<template functional>
    <tbody v-once v-pre>
        <tr class="skeleton-row">
            <td class="tx-mobile-flex-content" colspan="10">
                <div class="tx-table-cell-icon"><svg /></div>
                <div class="tx-mobile-content">
                    <div class="tx-mobile-content__top">
                        <div class="tx-mobile-content-row">
                            <span
                                class="skeleton"
                                style="width: 100%; margin-right: 12px"
                            >
                                Address
                            </span>
                            <span class="skeleton">1 day ago</span>
                        </div>
                        <div class="tx-mobile-content-row">
                            <div>&nbsp;</div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</template>

<script></script>

<style scoped>
.tx-mobile-content-row {
    transform: translateY(50%);
}
</style>
