
.widget-jettons a:hover {
    background: var(--body-light-muted-color);
}

@media screen and (max-width: 599px) {
    .widget-jettons > .widget-jettons-item {
        margin-left: 0;
        margin-right: 0;
    }
}
