
.tip-tooltip-wrap-content {
    text-wrap: wrap,
}

.tip-tooltip-wrapper {
    position: absolute;
    transform: translate(-50%, 0%);
    top: -37px;
    left: 50%;
    padding: 12px 14px;
    color: var(--body-text-color);
    border-radius: 12px;
    font-size: 13px;
    background: var(--card-background);
    background-clip: border-box;
    border: 1px solid var(--card-border-color);
    box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
    max-width: 400px;
    z-index: 1001;
}

.tip-tooltip-wrapper-left {
    transform: translate(0%, -50%);
    top: 50%;
    right: 0;
    left: auto;
}

.tip-tooltip-wrapper-right {
    transform: translate(0%, -50%);
    top: 50%;
    right: auto;
    left: 0;
}

.tip-tooltip-wrapper-bottom {
    // overflow-wrap: break-word;
    white-space: nowrap;
    top: 37px;
}

.tip-tooltip-bottom-before {
    position: absolute;
    width: 25px;
    height: 5px;
    top: -1px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    color: var(--card-border-color);
    z-index: 0;
}

.tip-tooltip-bottom-after {
    position: absolute;
    width: 20px;
    height: 10px;
    top: -3.6px;
    left: 50%;
    transform: translateX(-50%) rotate(180deg);
    color: var(--card-background);
    z-index: 5,
}

.tip-tooltip-left-before {
    position: absolute;
    width: 26px;
    height: 6px;
    bottom: 50%;
    top: 50%;
    left: -14px;
    transform: translateY(-50%) rotate(90deg);
    color: var(--card-border-color);
    z-index: 0;
}

.tip-tooltip-left-after {
    position: absolute;
    width: 23px;
    height: 10px;
    bottom: 50%;
    top: 50%;
    left: -13.3px;
    transform: translateY(-50%) rotate(90deg);
    color: var(--card-background);
    z-index: 5;
}
.tip-tooltip-right-before {
    position: absolute;
    width: 26px;
    height: 6px;
    bottom: 50%;
    top: 50%;
    right: -14px;
    transform: translateY(-50%) rotate(-90deg);
    color: var(--card-border-color);
    z-index: 0;
}

.tip-tooltip-right-after {
    position: absolute;
    width: 23px;
    height: 10px;
    bottom: 50%;
    top: 50%;
    right: -13.3px;
    transform: translateY(-50%) rotate(-90deg);
    color: var(--card-background);
    z-index: 5;
}
