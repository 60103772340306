
.addressbook-dropdown {
    &__item {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        width: 100%;

        &:hover {
            background: var(--tx-table-hide-button-background);

            .addressbook-dropdown__controls {
                display: flex;
            }
        }

        &--name {
            font-weight: 500;
        }

        &--address {
            font-size: 12px;
            width: 95%;
        }
    }

    &__link {
        gap: 5px;
        padding: 10px 13px;
        white-space: nowrap;
        cursor: pointer;
        color: var(--body-text-color);
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &:hover {
            color: inherit;
            text-decoration: none;
        }
    }

    &__controls {
        position: absolute;
        display: none;
        gap: 8px;
        right: 0;
        top: 0;
        height: 100%;
        padding: 0 10px 0 30px;
        align-items: center;
        color: var(--body-muted-text-color);
        background: linear-gradient(to right, transparent 0%, var(--tx-table-hide-button-background) 30%, var(--tx-table-hide-button-background) 100%);

        svg {
            width: 20px;
            height: 20px;
            transition: .15s color;
            cursor: pointer;

            &:hover {
                color: var(--body-text-color);
            }
        }
    }
}
