
.search-result-recent__button {
    fill: var(--badge-grey-color);
    width: 16px;
    height: 16px;
    padding: 8px;
    margin: -8px;
    border-radius: 50%;
    &:hover {
        fill: var(--badge-red-color);
    }
}
