
.card-market-jetton:hover {
    color: var(--body-text-color);
    text-decoration: none;
}

.card-market-body {
    width: 100%;
}

.card-market-jetton:first-child {

}

.card-market-jetton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--body-text-color);
    margin: 3px 6px;
    padding: 10px 9px;
    border-radius: 10px;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;

    .card-market-jetton-item {
        display: flex;
        align-items: center;
    }
    .card-market-jetton-image {
        width: 24px;
        height: 24px;
        padding-right: 8px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
    }
    .card-market-jetton-name {
        font-weight: 500;
    }
    .card-market-jetton-price {
        margin-right: 20px;
        font-weight: 500;
    }
    .card-jetton-green {
        color: #3fb950;
    }
    .card-jetton-red {
        color: #f74b4c;
    }
}

.card-market-jetton:last-child {
    margin-bottom: 0px;
}

.card-market-jetton:hover {
    background: var(--body-light-muted-color);
}
@media screen and (max-width: 599px) {

}
