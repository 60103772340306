
.ad-block-floating {
    position: fixed;
    bottom: 20px;
    right: 3dvw;
    width: 385px!important;
    z-index: 10000;

    &--mobile {
        position: fixed;
        bottom: 16px;
        z-index: 10000;
    }
}

.neuton-enter, .neuton-leave-to {
    opacity: 0;
    transform: translateX(10px);
}

.neuton-enter-active, .neuton-leave-active {
    transition: opacity .8s, transform 1.2s ease-out;
}

.neuton-mobile-enter, .neuton-mobile-leave-to {
    opacity: 0;
    transform: translateY(20px);
}

.neuton-mobile-enter-active, .neuton-mobile-leave-active {
    transition: opacity .8s, transform 1.2s ease-out;
}
