
.chart-bar-mini {
    margin-top: 20px;
}
.chart-preloader {
    position: relative;
    bottom: -35px;
    svg {
        fill: var(--body-light-muted-color);
    }
}
.widget-content {
    canvas {
        cursor: pointer;
        transition: .4s;
    }
}
.widget-content:hover {
    text-decoration: none;
}
.chart-bar-mini__graph{
    height: 110px!important;
    max-height: 110px!important;
    padding-bottom: 2px!important;
}
.widget-type-market-cap, .widget-price-market-cap {
    text-align: right;
}
.widget-type {
    text-transform: uppercase;
    font-weight: 500;
}
