
.labels-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: var(--body-muted-text-color);
    font-size: 12px;
    padding: 10px 0;
}
.chartContainer {
    pointer-events: none;
}
