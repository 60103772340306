
.widget-apps-img {
    &--skeleton {
        width: 50px;
        height: 50px;
        border-radius: 12px;
        margin-right: 15px;
        overflow: hidden;
        content: "";
        background-color: var(--body-light-muted-color);
    }
}
