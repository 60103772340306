
.tooltip {
    display: none;
    background: var(--chart-tooltip-background);
    border-radius: 12px;
    box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
    color: var(--chart-tooltip-color);
    font-size: 13px;
    padding: 8px 12px;
    position: absolute;
    z-index: 99999;
    min-width: 200px;
    pointer-events: none;
}

.chart-tooltip {
    &__body {
        border-spacing: 4px;

        td {
            white-space: nowrap;
        }
    }

    &__header {
        padding: 4px 4px 6px 4px;
        font-size: 12px;
    }

    &__line {
        display: block;
        content: '';
        width: 7px;
        height: 7px;
        margin-top: 2px;
        border: 2px solid #fff;
        border-radius: 10px;
        box-shadow: 0 1px 6px 1px var(--header-box-shadow-color);
        margin-right: 4px;
    }

    &__label {
        color: var(--body-muted-text-color);
    }

    &__value {
        font-weight: 500;
    }

    &__line-index,
    &__line-noindex,
    &__line-up {
        background: var(--chart-line-green-color);
    }

    &__line-down {
        background: #EF5350;
    }

    &__arrow {
        display: inline-block;
        width: 7px;
        height: 5px;
        position: relative;
        border: 2px solid #fff;
        border-bottom: none;
        border-radius: 3px;
        background-color: var(--button-slider-opacity);
        transform: translateY(-1px);
        box-shadow: 0 1px 6px 1px var(--header-box-shadow-color);

        &:after {
            position: absolute;
            bottom: -3px;
            left: 0;
            display: block;
            content: '';
            width: 7px;
            height: 7px;
            border: 2px solid #fff;
            border-bottom: none;
            border-right: none;
            border-radius: 3px 3px 8px 3px;
            background-color: var(--button-slider-opacity);
            transform: rotate(225deg);
        }
    }
}
