
.last-blocks-table {
    overflow: hidden;

    td, th {
        text-align: center;
    }

    tr:last-child > td {
        border-bottom: 1px solid var(--card-border-color);
    }
}
