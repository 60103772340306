
.jetton-meta-price {
    &__change {
        padding: 3px 6px;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;

        &--green {
            color: var(--badge-green-color);
            background-color: var(--badge-green-background-color);

            &::before {
                margin-right: -2px;
                content: '+';
            }
        }

        &--red {
            color: var(--badge-red-color);
            background-color: var(--badge-red-background-color);

            &::before {
                margin-right: -2px;
                content: '—';
            }
        }

        &--skeleton {
            color: transparent;
            background-color: var(--body-light-muted-color);
        }
    }
}
