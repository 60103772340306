
.market-information {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 24px 18px;
    margin-bottom: 22px;
}

.market-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 26px 16px;
    flex: 1;

    header {
        margin-top: 3px;
        color: var(--card-header-color);
    }

    &__value {
        font-size: 34px;
    }
}

.jetton-indexer-card {
    border-radius: 0 0 12px 12px !important;
}

.jetton-indexer-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    button {
        background-color: var(--card-background);
        background-clip: border-box;
        border: 1px solid var(--card-border-color);
        border-radius: .5rem;
        box-shadow: 0 .5rem 1.2rem var(--card-box-shadow-color);
        font-size: 14px;
        color: var(--body-muted-text-color);
        padding: 6px 12px;
        cursor: pointer;
    }

    button:not(:last-child) {
        margin-right: 12px;
    }

    .active {
        background-color: var(--big-blue-button-background);
        border-color: var(--big-blue-button-background);
        color: white;
    }
}

.jetton-indexer-table {
    overflow: hidden;
    border-collapse: collapse;
    width: 100%;
    border-radius: 0;

    tbody {
        padding: 18px 0;
    }

    thead {
        background-color: var(--tx-table-thead-alt-background);
        color: #6c757e;
        border: none;
    }

    td,
    th {
        white-space: nowrap;
        overflow: hidden;
        border-top: 1px solid var(--card-border-color);
        font-size: 14px;
        max-width: 250px;
    }

    th {
        padding: 10px 0;
        font-weight: 500;
        border: none;
    }

    td {
        p {
            margin: 0;
        }

        p:first-child {
            padding-bottom: 3px;
        }
    }

    tr>th:first-child,
    tr>td:first-child {
        padding-left: 18px;
    }

    tr>th:last-child,
    tr>td:last-child {
        padding-right: 18px;
    }

    &__icon {
        width: 32px;
        height: 32px;
    }

    .jetton-idx {
        width: 26px;
        text-align: left;
    }

    .jetton-m-cap {
        width: auto;
        text-align: left;
        padding: 0 12px;
    }

    .jetton-name {
        text-align: left;
    }

    .jetton-price {
        text-align: right;
        width: 82px;
    }

    .jetton-hour {
        text-align: center;
        padding-left: 30px;
    }

    .jetton-day {
        text-align: center;
    }

    .jetton-week {
        text-align: center;
    }

    .jetton-mc {
        text-align: center;
    }

    .jetton-volume {
        text-align: center;
    }

    .jetton-last {
        width: 120px;
    }
}

.market-info-section {
    gap: 20px;
    display: flex;
    margin-bottom: 20px;
}

.market-info-item {
    width: 33.333%;
}

.market-info-section .market-info-block {
    margin-top: 0 !important;
}

.market-info-block {
    height: 100%;

    .card-market-box {
        // align-items: flex-start;
        // display: flex;
        // flex: 1;
        // flex-direction: column;
    }

    .card-market-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .card-market-title {
        font-weight: 500;
        text-transform: uppercase;
    }

    .card-market-title,
    .card-market-period {
        color: var(--card-header-tab-color);
        padding: 12px 16px 0 16px;
    }
}

@media screen and (max-width: 991px) {
    .market-info-wrapper::-webkit-scrollbar {
        display: none;
    }

    .market-info-section {
        display: flex;
        position: relative;
    }

    .market-info-wrapper {
        margin: 0 -8px;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-left: 8px;
        padding-right: 8px;
        scroll-snap-type: x mandatory;
        scrollbar-color: transparent transparent;
        scrollbar-width: none;
    }

    .market-info-section .market-info-item {
        width: inherit;
        min-width: 92%;
    }

    .market-info-item {
        scroll-snap-align: center;
    }

    .market-info-section .market-info-item:last-child {
        padding-right: 8px;
    }
}

@media screen and (max-width: 599px) {
    .jetton-indexer-table {
        td {
            white-space: normal;
        }

        &__icon {
            width: 40px;
            height: 40px;
        }

        tr>th:first-child,
        tr>td:first-child {
            padding-left: 12px;
        }

        tr>th:last-child,
        tr>td:last-child {
            padding-right: 12px;
            text-align: right !important;
        }
    }

    .jetton-indexer-table {
        border-radius: 0;
    }

    .market-header {
        padding: 12px 8px;
        margin-bottom: 5px;
    }
}
