
.config-content-address {
  &__title {
    margin-bottom: 6px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__icon {
    width: 14px;
    height: 14px;
    transform: translateY(2px);
    margin-right: 3px;
    margin-left: 1px;
  }
}

.config-address-items {
  display: flex;
  align-items: center;
}
