
.card-status {
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 20px;
    &__skeleton {
        background: var(--success-skeleton-transaction-background);
        border: 1px solid var(--success-skeleton-transaction-border);
    }
    &__success {
        background: var(--card-background);
        border: 1px solid var(--card-border-color);
        .card-status-st-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
        .card-status-title {
            color: var(--success-status-transaction-border);
        }

        .card-status-time {
            font-size: 14px;
            color: var(--body-muted-text-color);
            white-space: nowrap;
        }

        .card-status-icon {
            color: var(--success-status-transaction-border);
        }

        .card-status-muted-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
    }
    &__failed {
        background: var(--card-background);
        border: 1px solid var(--card-border-color);
        .card-status-st-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
        .card-status-title {
            color: var(--fail-status-transaction-border);
        }

        .card-status-time {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }

        .card-status-icon {
            color: var(--fail-status-transaction-border);
        }

        .card-status-muted-text {
            font-size: 14px;
            color: var(--body-muted-text-color);
        }
    }
}

.card-status-address {
    width: 150px;
    font-size: 14px;
}

.card-status-items {
    display: flex;
    gap: 20px;
    align-items: center;
}

.card-status-icon {
    width: 43px;
    svg {
        display: block;
    }
}

.card-status-body {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
}

.card-status-title {
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: 500;
}

.card-status-skeleton-image {
    width: 43px;
    height: 43px;
    border-radius: 50px;
    overflow: hidden;
}

.card-status-skeleton-image .skeleton {
    height: 100%;
    width: 100%;
}

.status-desc-address {
    max-width: 240px;
}

.status-desc-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    font-size: 14px;
}

.status-desc-count {
    font-weight: 500;
}

.status-desc-ticker {
    font-weight: 500;
}

@media all and (max-width: 599px) {

    .card-status-body {
        flex-direction: column;
        flex-grow: inherit;
    }

    .card-status-address {
        margin: 10px auto 10px auto;
    }
}
