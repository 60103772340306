
.interval-selector {
    display: flex;
    align-items: center;
    padding: 3px;
    margin: -3px;
    border-radius: 8px;
    background: var(--chart-interval-selector-background);
    color: var(--chart-interval-selector-color);
    text-transform: none;
    margin-left: auto;

    &__item {
        padding: 4px 12px;
        // min-width: 90px;
        text-align: center;
        border-radius: 5px;
        font-size: 12px;
        font-weight: normal;
        cursor: pointer;
        white-space: nowrap;
        transition: all .3s ease-in-out;
        text-transform: lowercase;

        &--active {
            background: var(--chart-interval-selector-item-background);
            color: var(--chart-interval-selector-item-active-color);
        }
    }

    &__item::first-letter {
        text-transform: uppercase;
    }
}

@media screen and (max-width: 599px) {
    .interval-selector {
        width: 100%;
        margin-right: auto;
        margin-left: 0;
        margin: 12px 0px 2px -2px;
        &__item {
            flex: 1;
            text-align: center;
            min-width: auto;
        }
    }
}
