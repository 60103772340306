
@media screen and (max-width: 599px) {
    .page-app-block-similar .t-row {
        gap: 20px;
    }
}

@media screen and (max-width: 480px) {
    .page-app-block-similar .t-row {
        gap: 0px;
    }

    .page-app-similar-app {
        margin: 0 -16px;
        border-radius: 0!important;
        border: 0px solid var(--card-border-color);
        border-width: 1px 0!important;
        margin-bottom: -1px; // collapses border between items
    }

}
