
.ui-up-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: -80px;
    right: 30px;
    cursor: pointer;
    width: 48px;
    height: 48px;
    z-index: 8000;
    transition: all .2s;
    background: var(--card-background);
    border: 1px solid var(--card-border-color);
    border-radius: 50px;
    box-shadow: 0 0.5rem 1.2rem var(--card-box-shadow-color);
    color: var(--body-text-color);
    opacity: 0;

    @media (hover: hover) {
        &:hover {
            opacity: 1;
            color: var(--blue-bright);
        }
    }

    &--visible {
        bottom: 30px;
        opacity: 0.92;
    }

    &__icon {
        fill: currentColor;
        height: 10px;
        width: 10px;
    }
}

@media screen and (max-width: 768px) {
    .ui-up-button {
        &--index {
            display: none;
        }
    }
}
