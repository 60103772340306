
.jetton-owner a {
    font-weight: 400!important;
}

.jetton-owner a:hover {
    text-decoration: underline;
}

.jetton-copy-items-inner {
    display: flex;
    align-items: center;
}

.jetton-owner {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
    width: 100%;
    white-space: nowrap;
    text-align: right;
}
