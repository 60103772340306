

$line-height: 24px;

.app__description {
    max-width: 580px;
    font-weight: 400;
    line-height: $line-height;
    color: var(--body-text-color);

    &--loading {
        width: 100%;
        min-height: 115px;
        margin-top: 24px;
    }

    &-container {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        display: -webkit-box;
        margin-bottom: -20px;
        overflow: hidden;
        position: relative;
        z-index: 800;

        &--blur::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 78px;
            background: linear-gradient(to top, var(--card-background) 20%, transparent);
            pointer-events: none;
        }

        &--expanded {
            display: block;
        }

        & p:first-child {
            margin-top: 0;
        }

        & p:last-child {
            margin-top: 0;
        }
    }

    &-expander {
        margin-top: -10px;
        margin-bottom: -10px;
        position: relative;
        font-weight: 500;
        color: var(--blue-bright);
        cursor: pointer;
        z-index: 900;
        &:hover {
            text-decoration: underline;
        }
    }
}
